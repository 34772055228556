import React from 'react'
import {useStaticQuery,graphql} from 'gatsby'
const getData = graphql`
    {
        site {
            info:siteMetadata {
            data
            description
            title
            person {
                apellido
                nombre
            }
            }
        }
    }
`

const Header3 = () => {
    const {
        site: {
            info: {
                title,
                person: {nombre,apellido}
            }
        }
    } = useStaticQuery(getData)

    return (
        <div className="text-center py-12">
            <h1>Titulo: {title}</h1>
            <h1>Nombre: {nombre}</h1>
            <h1>Apellido: {apellido}</h1>
        </div>
    )
}

export default Header3

import React from 'react'
import {useStaticQuery,graphql} from 'gatsby'
const getData = graphql`
    {
        site {
            siteMetadata {
            data
            description
            title
            person {
                apellido
                nombre
            }
            }
        }
    }
`

const Header2 = () => {
    const data = useStaticQuery(getData)
    console.log(data)
    return (
        <div className="text-center py-12">
            <h1>Titulo: {data.site.siteMetadata.title}</h1>
            <h1>Nombre: {data.site.siteMetadata.person.nombre}</h1>
        </div>
    )
}

export default Header2

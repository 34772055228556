import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentName = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          data
          description
          title
          person {
            apellido
            nombre
          }
        }
      }
    }
  `)
  return (
    <div className="text-center py-12">  
    <pre>{JSON.stringify(data, null, 4)}</pre>
    <div className="py-8">{data.site.siteMetadata.person.nombre}</div>
    <h1 className="text-3xl py-8">{data.site.siteMetadata.description}</h1>
    </div>
  )
}

export default ComponentName

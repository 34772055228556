import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Header2 from '../components/Header2'
import Header3 from '../components/Header3'

const queries = () => {
    return (
        <Layout>
            <h1 className="text-5xl text-center py-8">Sitio de Ejemplo</h1>
            <Header/>
            <h1 className="text-5xl text-center py-8">GraphQL Header2</h1>
            <Header2/>
            <h1 className="text-5xl text-center py-8">GraphQL Header2</h1>
            <Header3/>

        </Layout>
    )
}

export default queries
